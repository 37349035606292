var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Component, Vue, } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import 'firebase/auth';
import authService from '../services/auth';
import { bchidentity_url } from '../db';
var main = namespace('Main');
var alertStore = namespace('Alert');
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errorCode = '';
        _this.loginUrl = '';
        return _this;
    }
    Login.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1, redirect;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.createChallenge()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.displayAlert("Failed to create login challenge: ".concat(error_1));
                        this.loginUrl = 'ERROR';
                        return [3 /*break*/, 3];
                    case 3:
                        redirect = function () {
                            console.log('Redirecting to dashboard');
                            if (_this.$router.currentRoute.name !== 'dashboard') {
                                _this.$router.push({ name: 'dashboard' });
                            }
                            else {
                                console.log('Already there');
                            }
                            // Workaround for firebase only auth observer only detecting anonymous
                            // login, and not custom token login.
                            // authService.forceAuthEmit(null);
                        };
                        if (authService.authTokenExists()) {
                            console.log('User should log out before returning to login page.');
                            redirect();
                            return [2 /*return*/];
                        }
                        authService.observeAuth()
                            .subscribe(function (currentUser) { return __awaiter(_this, void 0, void 0, function () {
                            var isAuthenticated;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        isAuthenticated = authService.isAuthenticated();
                                        if (isAuthenticated) {
                                            return [2 /*return*/];
                                        }
                                        if (!(currentUser && (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isAnonymous))) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.storeAuthToken(currentUser)];
                                    case 1:
                                        if (_a.sent()) {
                                            // Auth token has now been stored in local storage.
                                            // Redirecting to dashboard will trigger signin.
                                            redirect();
                                        }
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); }, function (error) {
                            _this.displayAlert("Error ".concat(error));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Login.prototype.createChallenge = function () {
        return __awaiter(this, void 0, void 0, function () {
            var newCurrentUser, currentUserId, challenge;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('Creating challenge');
                        return [4 /*yield*/, authService.signInAnonymously()];
                    case 1:
                        newCurrentUser = _a.sent();
                        currentUserId = newCurrentUser.uid;
                        return [4 /*yield*/, authService.requestChallenge()];
                    case 2:
                        challenge = _a.sent();
                        this.loginUrl = bchidentity_url(challenge, currentUserId);
                        return [2 /*return*/];
                }
            });
        });
    };
    // eslint-disable-next-line class-methods-use-this
    Login.prototype.storeAuthToken = function (currentUser) {
        return __awaiter(this, void 0, void 0, function () {
            var currentUserId, authToken, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('storeAuthToken');
                        currentUserId = currentUser.uid;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, authService.listenForAuthToken(currentUserId)];
                    case 2:
                        authToken = _a.sent();
                        localStorage.setItem('auth-token', authToken);
                        return [2 /*return*/, true];
                    case 3:
                        e_1 = _a.sent();
                        this.displayAlert("Error fetching auth token: ".concat(e_1));
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        alertStore.Action
    ], Login.prototype, "displayAlert", void 0);
    __decorate([
        alertStore.Action
    ], Login.prototype, "hideAlert", void 0);
    __decorate([
        main.Action
    ], Login.prototype, "signIn", void 0);
    Login = __decorate([
        Component
    ], Login);
    return Login;
}(Vue));
export default Login;
